@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 100;
    src: url("./Gilroy-Light.otf");
  }


  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    src: url("./Gilroy-ExtraBold.otf");
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 900;
    src: url("./Gilroy-ExtraBold.otf");
  }