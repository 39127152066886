@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 100;
    src: url("./static/Quicksand-Light.ttf");
  }
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 200;
    src: url("./static/Quicksand-Light.ttf");
  }


  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    src: url("./static/Quicksand-Light.ttf");
  }
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url("./static/Quicksand-Regular.ttf");
  }
  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    src: url("./static/Quicksand-Medium.ttf");
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    src: url("./static/Quicksand-SemiBold.ttf");
  }


  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: url("./static/Quicksand-Bold.ttf");
  }

  @font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 900;
    src: url("./static/Quicksand-Bold.ttf");
  }


