@mixin width-class($size) {
    .w#{$size} {
      width: $size * 1px !important;
    }
  }

  @mixin border-radius-class($radius) {
    .br-#{$radius} {
      border-radius: $radius * 1px  !important;
    }
  }

  @mixin dynborderradius($size-list) {
    @each $size in $size-list {
      @include border-radius-class($size)
    }
  }

  @include dynborderradius(5 10 20 30 50);