.classicBanner {
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .news-banner {
    background-color: #000;
    color: #fff;
    padding: 10px 0;
  }

  .logo img {
    max-height: 50px;
  }

  .headline h1 {
    margin: 0;
    font-size: 1.5vw;
  }

}