.baseBanner {
  background-color: cadetblue;
  .marquee {
    height: 25px;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .text {
    display: block;
    // width: 100%;
    // height: 30px;

    position: relative;
    overflow: hidden;

    animation: marquee 15s linear infinite;
  }

  @keyframes marquee {
    0% {
      left: 100%;
    }

    100% {
      left: -100%;
    }
  }

}