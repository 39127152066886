
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 200;
    src: url("./Prompt-ExtraLight.ttf");
  }


  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 300;
    src: url("./Prompt-Light.ttf");
  }
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    src: url("./Prompt-Regular.ttf");
  }
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    src: url("./Prompt-Medium.ttf");
  }

  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 600;
    src: url("./Prompt-SemiBold.ttf");
  }


  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 700;
    src: url("./Prompt-Bold.ttf");
  }


  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 800;
    src: url("./Prompt-ExtraBold.ttf");
  }
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 900;
    src: url("./Prompt-Black.ttf");
  }





  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 100;
    src: url("./Prompt-ThinItalic.ttf");
  }


  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 200;
    src: url("./Prompt-ExtraLightItalic.ttf");
  }


  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 300;
    src: url("./Prompt-LightItalic.ttf");
  }
  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 400;
    src: url("./Prompt-Italic.ttf");
  }
  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 500;
    src: url("./Prompt-MediumItalic.ttf");
  }

  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 600;
    src: url("./Prompt-SemiBoldItalic.ttf");
  }


  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 700;
    src: url("./Prompt-BoldItalic.ttf");
  }


  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 800;
    src: url("./Prompt-ExtraBoldItalic.ttf");
  }
  @font-face {
    font-family: 'Prompt';
    font-style: italic;
    font-weight: 900;
    src: url("./Prompt-BlackItalic.ttf");
  }