
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url("./Poppins-ExtraLight.ttf");
  }


  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url("./Poppins-Light.ttf");
  }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url("./Poppins-Regular.ttf");
  }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url("./Poppins-Medium.ttf");
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url("./Poppins-SemiBold.ttf");
  }


  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url("./Poppins-Bold.ttf");
  }


  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url("./Poppins-ExtraBold.ttf");
  }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url("./Poppins-Black.ttf");
  }





  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: url("./Poppins-ThinItalic.ttf");
  }


  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: url("./Poppins-ExtraLightItalic.ttf");
  }


  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: url("./Poppins-LightItalic.ttf");
  }
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url("./Poppins-Italic.ttf");
  }
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: url("./Poppins-MediumItalic.ttf");
  }

  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: url("./Poppins-SemiBoldItalic.ttf");
  }


  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: url("./Poppins-BoldItalic.ttf");
  }


  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: url("./Poppins-ExtraBoldItalic.ttf");
  }
  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: url("./Poppins-BlackItalic.ttf");
  }