$dark: #000000;
$dark-hover: #1f1f23;
$primary-color: #000000; // primary color for all components
$link-color: rgb(0, 112, 243); // link color
$success-color: #52c41a; // success state color
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color
$font-size-base: 14px; // major text font size
$heading-color: rgba(0, 0, 0, 0.85); // heading text color
$text-color: rgba(0, 0, 0, 0.65); // major text color
$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 4px; // major border radius
$border-color-base: #d9d9d9; // major border color
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

$font-size-base: 15px;
$font-weight-base: 300;
$font-weight-label: 400;
// $primary-color: #2e4258;
// height rules
$height-base: 52px;
$height-lg: 60px;
$height-sm: 44px;

//colors
$color-grey : #f8f8f8;
$color-dark-grey : #646464;
$light-grey : #f6f6ff;
$light-color: #f6f6ff;
$dark-color: $dark;

$violet: $primary-color;
$green:#d9f6d2;
$red:#fbb8db;