.subscriptionModal{
    .ant-modal-header {
        color: #fff;
        background: #000;
        border-bottom: 1px solid #000000;
    }
    .ant-modal-body {
        color: #fff !important;
        background: #000;
    }

    .ant-modal-footer {
        background: black;
        border-top: 1px solid #000000;

    }
}
.package-features{

    .title{
        font-size: 20px;
    }
    .value{
        // color: #fff;
        font-size: 20px;
    }
}

.charges{
    padding: 10px 5px;
    margin: 10px 5px;
    border: 2px dashed #dcdcdc;
}

.features-container{
    margin: 10px 0px;
    padding: 10px 15px;
    background-color: #1890ff;
}