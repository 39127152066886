@import 'variables.scss';

// .ant-btn{

//     .ant-btn-dark{
//         background-color: $dark !important;
//         border-color: $dark;
//     }
// }

.ant-btn-dark {
    background-color: $dark !important;
    border-color: darken($color: $dark, $amount: 5) !important ;
    color: lighten($color: $dark, $amount: 70) !important;
    transition: border-color .2s ease,background-color .2s;
    &:hover{
       background-color: lighten($color: $dark, $amount: 10) !important;
       border-color: darken($color: $dark, $amount: 5) !important ;
    }
}
.ant-btn-dark-gray {
    background-color: $color-dark-grey !important;
    border-color: darken($color: $color-dark-grey, $amount: 5) !important ;
    color: lighten($color: $color-dark-grey, $amount: 70) !important;
    transition: border-color .2s ease,background-color .2s;
    &:hover{
       background-color: lighten($color: $color-dark-grey, $amount: 10) !important;
       border-color: darken($color: $color-dark-grey, $amount: 5) !important ;
    }
}

.ant-btn-success {
    background-color: #1B8C88 !important;
    border-color: #1B8C88 !important;
    color: #ffffff !important;
    transition: border-color .2s ease,background-color .2s;
    &:hover{
       background-color: darken($color: #1B8C88, $amount: 5) !important;
       border-color: darken($color: #1B8C88, $amount: 5) !important ;
    }
}



.ant-btn-dangers {
    background-color: $red !important;
    border-color: darken($color: $red, $amount: 5) !important ;
    color: lighten($color: $red, $amount: 70) !important;
    &:hover{
       background-color: lighten($color: $red, $amount: 10) !important;
       border-color: darken($color: $red, $amount: 5) !important ;
    }
}

.ant-btn-primary {
    color: #fff;
    background-color: $violet  !important;
    border-color: #6d74c8;
  
  
    &:hover{
      background-color: #434ba8 !important;
      border-color: #6d74c8 !important;
    }
  }
  
  .ant-btn-warning {
    color: #000;
    background-color: $warning-color  !important;
    border-color:darken($color: $warning-color, $amount: 0.4);
  
  
    &:hover{
        color: #000;
      background-color:darken($color: $warning-color, $amount: 0.5)!important;
      border-color: darken($color: $warning-color, $amount: 0.6) !important;
    }
  }