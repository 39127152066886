@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 30s linear;
    }
  }

@media only screen and (max-width: 1366px){

  

}
@media only screen and (min-width: 1023px){

  .carousel-player{
    width: 640px !important;
    height: 360px !important;
  }

}
@media only screen and (max-width: 1024px){

  .activeHeroCarousel{
    translate:  0px 10px;
  }



}
@media only screen and (max-width: 991px){
  .socialsLogins{
  
    div { flex-grow: 1};
  }
  .nav-abs{
    padding :0px  0px !important;
  }
header nav ul li a {
    transition: all;

}
.home-logo-link{
  margin: 0px auto ;
}
.responsive-btn{
  transition: all;
}

#togglebtn{
  display: none;
}

.app2 h1 {
  line-height: 55px;
}
}


@media only screen and (max-width: 500px){
  #createStreamForm{
    .steps{

      display: none;
    }
  }
}
@media only screen and (max-width: 767px){
 
  .for-mobile{
    display: block !important;
  }
  .for-desktop{
    display: none !important;
  }
  .start-without-email{
    display: block;
  }
  .start-with-email{
    display: none;
  }
.readings{
  display: none;
}
.header-sub-text{
  // display: none;
  margin-top: 2rem;

}

.full-height{
  // min-height: 00vh;
  // max-height: 80vh;
}

.header-image-2 {
  display: grid;
  place-items: center;
}
.header-image-2 .headaer-image {
  width: auto;
  height: 30vh;
  display: block;
}

.app2 h1 {
  line-height: 50px;
}
.hero h1 {
  font-size: calc(30px + (45 - 22) * ((100vw - 300px) / (1920 - 300)));
}
.header .center-text {
  height: calc(100vh - 70px);
  }

  .rtmpContainer{
    padding: 20px !important;
  }
  .feature-cards {
    display: table;
  }
  .actioncard-description{
    display: none;
  }
}
@media only screen and (min-width: 768px){
  .for-mobile{
    display: none !important;
  }
  .for-desktop{
    display: block !important;
  }

.header-image-2 {
  display: none
}
.header-image-2 .headaer-image {
  display: none;
}

.hero h1 {
  font-size: calc(50px + (45 - 22) * ((100vw - 300px) / (1920 - 300)));
}

  .start-without-email{
    display: none;
  }
  .start-with-email{
    display: block;
  }

  .pricing{
    .container{
      max-width: 1300px !important;
    }
  }
}
